import React from 'react';

import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>404 Kurza twarz!</h1>
    <p>Podana strona nie istnieje :(</p>
  </>
);

export default NotFoundPage;
